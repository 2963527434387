import React from 'react'
import { gradeKeys } from '../components/CoursesStudyingItem'
import { EventTypeKeys } from '../api/services/sirius'

export default {
    appname: 'Courses Signpost',
    apptitle: 'FIT CTU Courses',
    build: {
        tooltip: 'Open the last build of the pages on GitLab',
        failed: 'Last build failed',
        success: 'Last build succeeded',
    },
    courses: {
        gradeTypes: {
            assesPoints: 'Assesment',
            grade: 'Grade',
            semesterPoints: 'Semester points',
            url: 'URL',
        } as gradeKeys,
        showAll: 'Show all courses',
        tags: {
            bachelor: 'Bachelor',
            both: 'Both',
            doctoral: 'Doctoral',
            master: 'Master',
            summer: 'Summer',
            winter: 'Winter',
            // departments
            18000: 'FIT',
            18101: 'DTCS',
            18102: 'DSE',
            18103: 'DDD',
            18104: 'DCS',
            18105: 'DAM',
            18106: 'DIS',
        },
    },
    dashboard: {
        studyingTitle: 'Courses I’m studying',
        teachingTitle: 'Courses I’m teaching',
    },
    error: {
        reqAuthErr: 'It appears, that your login session has expired. Please login again.',
        reqDescribe: (x: string): string => `The request to retrieve data from server ${x} has failed.`,
        reqNoResponse: 'Did not get any response.',
        reqOtherErr: 'There was some other error preventing retrievement of the data.',
        reqRequestErr: 'The request seems to be invalid. Please try again later.',
        reqTemporaryErr: 'The server is temporarily unavailable. Please try again later.',
    },
    eventTypes: {
        assessment: 'ASST',
        courseEvent: 'EVE',
        exam: 'EXAM',
        laboratory: 'LAB',
        lecture: 'LEC',
        tutorial: 'TUT',
    } as EventTypeKeys,
    footer: {
        copyright: '© 2019 Faculty of Information Technology, Czech Technical University in Prague',
        ddp: {
            title: 'Double Degree Program',
            href: '/NI-DBE/'
        },
        managed: 'Created and managed by ICT Department',
        manual: 'Manual',
        name: 'FIT CTU Courses Signpost',
        reportIssue: 'Report an issue',
        sfe: {
            title: 'Finals',
            longTitle: 'State Final Exams (SFE)',
            href: '/SFE/',
        },
        sourceCode: 'Source code',
    },
    issues: {
        issuesTooltip: 'issues',
        unassignedIssues: 'unassigned issues on GitLab',
    },
    loginAppeal: {
        loginButton: 'Login with your CTU account',
        text: <p>
            More features are available after authentication.
            Customize this page as you like — see relevant information for
            subjects you&apos;re studying or teaching, from other school systems,
            such as Grades, Timetable or KOS.
        </p>,
    },
    search: {
        placeholder: 'Search for courses...',
        title: 'Search results',
    },
    semesterSelector: {
        all: 'All Courses',
        current: 'Current Courses',
        semester: 'Semester',
    },
    settings: {
        close: 'Close',
        description: <p>
            Set your application preferences. Settings are currently only saved to Local Storage of this browser.
            In one of the next updates you can look forward to have persistent settings across multiple devices.
        </p>,
        experimentalTitle: 'Experimental features',
        generalTitle: 'General',
        hide: 'Hide',
        items: {
            language: 'Language',
            upcomingVisibility: 'Upcoming events visibility',
        },
        language: { cs: 'Czech', en: 'English' },
        save: 'Save',
        show: 'Show',
        title: 'User Settings',
        upcomingTitle: 'Upcoming events',
    },
    upcoming: {
        genericTitle: <>Upcoming events</>,
        months: [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ],
        noEvents: 'No events are upcoming today, or tomorrow.',
        seeMore: 'See more on timetable.fit.cvut.cz',
        todayTitle: <>Upcoming today</>,
        tomorrowTitle: <>Upcoming <span className='tomorrow'>tomorrow</span></>,
    },
    userMenu: {
        login: 'Login',
        logout: 'Logout',
        settings: 'Settings',
    },
    broadcastMessage: '',
}
