import React from 'react'
import { bugs as issuesUrl, homepage as homepageUrl, version } from '../../package.json'
import { AppCtxO, withContext } from '../AppContext'
import appIcon from '../images/course-pages-logo-small.svg'
import './Footer.css'

interface Props {
    style: 'full' | 'minimal'
}

const Footer: React.FC<Props & AppCtxO> = ({ style, ctx: { l } }) => (
    <div className='Footer'>
        <div className='container'>
            { style === 'full' ? (
                <ul className='links'>
                    <li><a href={ l.footer.sfe.href } title={ l.footer.sfe.longTitle }>{ l.footer.sfe.title }</a></li>
                    <li><a href='/manual/'>{ l.footer.manual }</a></li>
                    <li><a href={ homepageUrl } rel='external'>{ l.footer.sourceCode }</a></li>
                    <li><a href={ issuesUrl } rel='external'>{ l.footer.reportIssue }</a></li>
                </ul>
            ) : '' }
            <div className='app-name'>
                <img src={ appIcon } width='16' />
                { l.footer.name }
                <span className='app-version'>{ version }</span>
            </div>
            <div className='about'>
                <div className='rights'>
                    { l.footer.copyright }
                </div>
                <div className='mgmt'>
                    { l.footer.managed }
                </div>
            </div>
        </div>
    </div>
)

export default withContext<Props>(Footer)
